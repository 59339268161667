$primary-color: #1976d2;
$background-color: #f7f9f7;
$profile-picture-dimenssions: 120px;
$point-in-time-dimenssions: 15px;
$time-width: 120px;

.App {
  text-align: center;
  background-color: $background-color;
  padding-top: 70px;

  .main-details-container {
    background-color: $primary-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Quicksand', sans-serif;
    color: white;
    font-size: 30px;
    font-weight: 300;
    position: relative;
    padding-top: 70px;

    &.fixed {
      position: fixed;
      right: 0;
      left: 0;
      top: 0;
      padding-top: 0px;
      background-color: $primary-color;
      z-index: 1;
    }

    .profile-picture {
      position: absolute;
      width: $profile-picture-dimenssions;
      height: $profile-picture-dimenssions;
      top: -($profile-picture-dimenssions / 2);
      border-radius: 50%;
      border: 5px solid $primary-color;
      margin-top: 10px;
      background-image: url(/profile.jpg);
      background-size: 120%;
      background-position: 50%;
    }

    .name-container {
      margin-top: 10px;
      font-size: 35px;
    }

    .job-title-container {
      font-size: 16px;
      letter-spacing: 1.5px;
      margin-bottom: 5px;
      text-transform: uppercase;
    }

    .summary {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      font-size: 15px;
      font-weight: 400;
      max-width: 840px;
    }
  }

  .sections-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    max-width: 800px;
    margin: auto;

    &.with-margin-top {
      padding-top: 170px;
    }

    .section {
      width: 100%;

      .title {
        text-transform: uppercase;
        font-size: 20px;
        text-align: left;
        padding: 10px 0;
        color: $primary-color;
      }

      .stages-container {
        .stage {
          border-left: 5px solid $primary-color;
          margin-top: ($point-in-time-dimenssions / 2);
          position: relative;
          padding: 20px;
          display: flex;

          .point-in-time {
            width: $point-in-time-dimenssions;
            height: $point-in-time-dimenssions;
            border-radius: 50%;
            border: 5px solid $primary-color;
            position: absolute;
            top: -($point-in-time-dimenssions / 2);
            left: -($point-in-time-dimenssions);
            background-color: $background-color;
          }

          .time-container {
            text-align: left;
            font-size: 16px;
            font-family: 'Quicksand', sans-serif;
            font-weight: 300;
            width: $time-width;

            .logo {
              width: 50px;
              height: 50px;
              margin: 20px;
              margin-bottom: 0;
              margin-left: 5px;
              border-radius: 50%;
              object-fit: cover;
              border: 1px solid #ccc;
            }
          }

          .details {
            text-align: left;
            padding: 0 10px;
            width: 100%;

            .job-title {
            }

            .sub-title {
              font-weight: 300;
              margin-bottom: 10px;
              font-style: italic;
            }

            .description {
              font-weight: 300;
              font-size: 14px;
              list-style-type: none;
              padding-left: 0px;

              &.list {
                // padding-left: 40px;

                li {
                  list-style-type: disc;
                }
              }
            }
          }
        }
      }
    }

    .summary, .small-section {
      margin-top: ($point-in-time-dimenssions / 2);
      display: flex;
      flex-direction: column;
      width: 100%;

      .title {
        text-transform: uppercase;
        font-size: 20px;
        text-align: left;
        padding: 10px 0;
        color: $primary-color;
      }

      .stages-container {
        padding: 0px;

        .stage {
          border-left: 5px solid $primary-color;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 10px;

          .stage-title {
            margin-bottom: 10px;
          }

          .description {
            font-weight: 300;
            font-size: 14px;
            list-style-type: none;
            padding-left: 0px;
            text-align: left;
          }
        }
      }
    }

    .summary {

      .description {
        border-left: 5px solid $primary-color;
        font-weight: 300;
        font-size: 14px;
        list-style-type: none;
        padding-left: 20px;
        text-align: left;
      }
    }
  }
}

@media (max-width:800px)  {
  .logo {
    margin-left: 5px !important;
  }
}
